<template>
  <section class="relative flex flex-col lg:h-[calc(100vh-88px)] overflow-hidden">
    <div class="container lg:overflow-hidden w-full">
      <div class="w-full lg:max-w-1/2 pt-15 pb-10">
        <v-text
            class="text-bw-26 font-[550] leading-5 text-base mb-4"
            tag="p"
            option_key="analysisIndex.subTitle"
        ></v-text>
        <div
            class="text-3xl lg:text-2xl xl:text-45px leading-4xl xl:leading-55px text-primary font-[550] mb-10 xl:mb-16"
        >
          <v-content
              default_value=" Need analysis got you covered!
                <p class='text-bw-27 text-base leading-6 mt-6 font-normal'>
                    Finding a right insurance coverages is an adventure itself, and it's easy to get lost along the way.
                    Worry not, as Need Analysis is here to guide you on the path to discover what's important in your
                    life, thus lead you to the most fitting insurance choice! Are you ready?
                </p>"
              option_key="analysisIndex.content"
          >
          </v-content>
        </div>

        <button-primary
            :title="t('LET_IS_GET_STARTED')"
            :url="$t('ROUTER_ANALYSIS_START')"
            class="w-max rounded-full !font-[550] !text-xs !md:text-sm"
            padding="py-4 md:py-4 px-14 "
        ></button-primary>
        <div>
          <v-text
              class="text-#8B8B8B text-sm leading-6 mt-4 flex flex-col"
              tag="p"
              option_key="analysisIndex.subtitle2"
          ></v-text>
          <nuxt-link target="_blank" :to="terms_condition" class="text-primary cursor-pointer">
            {{ t('TERMS_AND_CONDITIONS') }}
          </nuxt-link>
        </div>
      </div>
    </div>
    <div class="hidden lg:block absolute lg:top-10 lg:right-0 -z-1 w-full h-full">
      <div class="relative flex items-center w-full h-full w-full lg:max-w-1/2 ml-auto">
        <img
            src="/images/global/analysis1.svg"
            alt="background"
            class="w-full h-full object-fill object-bottom transfrom scale-120"
        />
        <div class="w-25 sm:w-40 lg:w-40 xl:w-62 acpect-82/62 object-contain absolute left-0 top-0 z-3">
          <img class="animationSway w-full h-full" src="/images/global/masgotRight.png" alt="icon"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
export default {
  name: 'lifeStageAndGender'
}
</script>

<script setup lang="ts">
const {t} = useI18n()

const links_global = inject('globalSettings')?.links[0]

const privacy_policy = ref()
const terms_condition = ref()

const routesRender = () => {
  links_global?.translations.map((item) => {
    if (item?.languages_code == useRoute().params.langCode) {
      privacy_policy.value = item?.privacy_policy
      terms_condition.value = item?.terms_condition
    }
  })
}
watch(
    () => useRoute().params.langCode,
    () => {
      routesRender()
    }
)

onMounted(() => {
  routesRender()
})
</script>

<style></style>
