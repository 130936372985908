<template>
  <life-stage-and-gender></life-stage-and-gender>
</template>

<script setup lang="ts">
import LifeStageAndGender from './components/LifeStageAndGender.vue'
import {useGetLangCurrent} from '../../stores/useLanguageMenu'

definePageMeta({
  layout: 'analysis'
})

const settings = inject('globalSettings')
const {generateLangSwitch} = useGenerateUrl(settings)
const lang_switch = generateLangSwitch('analysis', useRoute().params.langCode)
useDirectusCollectionSeo('analysis_index', {}, useRoute().params.langCode)
const store = useGetLangCurrent()
onMounted(() => {
  store.lang_switch = lang_switch
})
</script>

<style></style>
